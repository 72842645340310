import { Suspense } from "react";
import { Canvas, extend, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { useRef } from "react";
import Frog from "./Frog";

extend({ OrbitControls });

const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.  // We need these to setup the OrbitControls component.  // https://threejs.org/docs/#examples/en/controls/OrbitControls
  const {
    camera,
    gl: { domElement },
  } = useThree();
  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef<OrbitControls>();

  useFrame((state) => {
    if (!controls.current) return null;
    return controls.current.update();
  });

  // @ts-ignore
  return <orbitControls ref={controls} args={[camera, domElement]} />;
};

const Scene = () => {
  return (
    <Canvas style={{ width: "100vw", height: "100vh" }}>
      <CameraControls />
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Suspense fallback={null}>
        <Frog />
      </Suspense>
    </Canvas>
  );
};

export default Scene;
