import { Cylinder, Sphere, useTexture } from "@react-three/drei";
import { useEffect, useState } from "react";
import { animate, useMotionValue } from "framer-motion";

const lightGreen = "#72CF8F";
const darkGreen = "#49A383";
// const veryLightGreen = "#DFFEF3";

const Frog = () => {
  const [squish, setSquish] = useState<number>(1);
  const texture01Url = "./assets/tummy-texture-v2.png";
  // const texture02Url = "./assets/eye-texture-03.png";
  const texture03Url = "./assets/eye-texture-03a.png";
  const colorMap = useTexture(texture01Url);
  const eyeColorMap02 = useTexture(texture03Url);

  squishEyes(squish);

  function squishEyes(v: number) {
    eyeColorMap02.offset.setX(0.25);
    const offset = v * 0.33 - 0.1;
    const repeat = v * -0.6 + 1.0;
    eyeColorMap02.offset.setY(offset);
    eyeColorMap02.repeat.setY(repeat);
  }

  const motionValue = useMotionValue(0);

  function handleClick() {
    console.log("Click");
    // setColor01("orangered");
  }

  useEffect(() => {
    let timeout: any | null = null;
    let controls: any | null = null;
    function animateLoop() {
      timeout = setTimeout(() => {
        controls = animate(motionValue, [1, 0, 1], {
          ease: "easeInOut",
          onComplete: () => {
            animateLoop();
          },
          onUpdate: (v) => {
            setSquish(v);
          },
        });
      }, 500 + Math.random() * 2000);
    }
    animateLoop();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (controls) {
        controls.stop();
      }
    };
  }, []);

  return (
    <group scale={2}>
      <group>
        <Sphere position={[-0.2, 0.5, 0]} args={[0.125, 32, 32]}>
          <meshStandardMaterial map={eyeColorMap02} attach={"material"} />
        </Sphere>
        <Sphere position={[0.2, 0.5, 0]} args={[0.125, 32, 32]}>
          <meshStandardMaterial map={eyeColorMap02} attach={"material"} />
        </Sphere>
      </group>
      <group onClick={handleClick}>
        <mesh position={[0, 0, 0]}>
          <sphereGeometry attach="geometry" args={[0.5, 32, 32]} />
          <meshStandardMaterial color={lightGreen} />
        </mesh>

        <Cylinder args={[0.5, 0.5, 0.5, 32]} position={[0, -0.25, 0]}>
          <meshStandardMaterial attachArray="material" map={colorMap} />
          <meshStandardMaterial attachArray="material" color="lightGreen" />
          <meshStandardMaterial attachArray="material" color="lightGreen" />
        </Cylinder>

        <mesh position={[-0.3, -0.125, 0]}>
          <sphereGeometry attach="geometry" args={[0.375, 32, 32]} />
          <meshStandardMaterial color={darkGreen} />
        </mesh>

        <mesh position={[0.3, -0.125, 0]}>
          <sphereGeometry attach="geometry" args={[0.375, 32, 32]} />
          <meshStandardMaterial color={darkGreen} />
        </mesh>

        <mesh position={[-0.6, -0.5, 0.15]} scale={[1, 1.5, 2]}>
          <sphereGeometry
            attach="geometry"
            args={[0.125, 32, 32, 0, 2 * Math.PI, 0, Math.PI / 2]}
          />
          <meshStandardMaterial color={darkGreen} />
        </mesh>

        <mesh position={[0.6, -0.5, 0.15]} scale={[1, 1.5, 2]}>
          <sphereGeometry
            attach="geometry"
            args={[0.125, 32, 32, 0, 2 * Math.PI, 0, Math.PI / 2]}
          />
          <meshStandardMaterial color={darkGreen} />
        </mesh>
      </group>
    </group>
  );
};

export default Frog;
